import { useState , useEffect , useCallback } from "react";
import axios from 'axios';
import {  useSelector , useDispatch} from "react-redux";
import { DatePicker,notification, Space  } from 'antd';
import ImageUpload from "../Parts/ImageUpload";
import { uiActions } from "../../store/ui-slice";

import dayjs from 'dayjs';




let EditEngine=(props)=>{

    let initialState={
        make:props.item.make,
        model:props.item.model,
        fuelType:props.item.fuelType,
        cc:props.item.cc,
        body:props.item.body,
        cyl:props.item.cyl,
        years:props.item.years,
        code:props.item.code,
        engineType:props.item.engineType,
        cam:props.item.cam,
        kw:props.item.kw,
        bore:props.item.bore,
        image:props.item.image,
        startYears:'',
        endYears:'',
    }

    let [data,setData]=useState(initialState)
    let [error,setError]=useState(null)
    //  years
    let [startYaers,setStartYears]=useState(null)
    let [endYears,setEndYears]=useState(null)
    // let [successMessage]
    let baseURL=useSelector(state=>state.ui.baseURL)
   
    const [api, contextHolder] = notification.useNotification();
    let dispatch=useDispatch()

    let inputChangeHandler=(e)=>{
        setData({...data,[e.target.name]:e.target.value})
    }


    let updateImages=(image)=>{
        setData({...data,['image']:image})

    }

    let fuelChange=(e)=>{
        setData({...data,[e.target.name]:e.target.value})
        console.log('+++++++ fuel change value is ',e.target.value)
    }

    const onChange = (date, dateString) => {
        // data.years=dateString
      };

      const openNotificationWithIcon = (type,msg,description) => {
        api[type]({
          message: msg,
          description:description,
        });
      };

    let submitHandler=async ()=>{
        setError(null)
        data.years=data.startYears.concat('-',data.endYears)
        try{
            let response=await axios.put(baseURL+'engine/update/'+props.item._id,data)
            if (response.status !== 200) {
                throw new Error("Something Went Wrong Try Again");
              }
              if (response.data.code !== 200) {
                throw new Error(response.data.message);
              }else{
                props.updateOBjectsArray(response.data.result,response.data.message,'success');

              }


        }catch(error){
            openNotificationWithIcon('error','Error',error.message)
        }


    }

    const onChangeStart = (date, dateString) => {
        setData({...data,'startYears':dateString})
        setStartYears(date)
          // data.years=dateString
        };
  
        const onChangeEnd = (date, dateString) => {
          setData({...data,'endYears':dateString})
          setEndYears(date)
            // data.years=dateString
          };


 

    useEffect(()=>{

        const myArray = data.years.split("-");
        console.log('++++ myarray ::::',myArray)
        setData({...data,'startYears':myArray[0],'endYears':myArray[1]})
        if(myArray[0]!==''){
            setStartYears(dayjs(myArray[0],'YYYY'))
        }
        if(myArray[1]!==''){
            setEndYears(dayjs( myArray[1],'YYYY'))
            
        }        
    },[])

    // console.log('++++++ data -----',data)

    return(
        <div className="w-full bg-gray-100 p-2">

            {error&&<p className="alert alert-danger">{error}</p>}
            {contextHolder}
            <div className="flex w-full mt-4">
                <div className="w-1/3">
                <label className="block">Brand</label>
                <select onChange={inputChangeHandler} value={data.make} name="make" className="pl-2 py-1  outline-none w-3/4">
                <option value="a">Select</option>
                <option value="a"></option>
                <option>ADE ENGINES        </option>
                 <option>ALFA ROMEO         </option>
                 <option>AUDI               </option>
                 <option>AUSTIN             </option>
                 <option>BAIC               </option>
                 <option>BAJAJ              </option>
                 <option>BAW                </option>
                 <option>BEDFORD            </option>
                 <option>BMW                </option>
                 <option>BYD                </option>
                 <option>CADILLAC           </option>
                 <option>CAM -CHINA AUTO MAN</option>
                 <option>CHANA              </option>
                 <option>CHERY              </option>
                 <option>CHEVROLET          </option>
                 <option>CHRYSLER           </option>
                 <option>CITROEN            </option>
                 <option>CMC                </option>
                 <option>DAEWOO             </option>
                 <option>DAIHATSU           </option>
                 <option>DATSUN             </option>
                 <option>DFM (CMF)          </option>
                 <option>DODGE              </option>
                 <option>EICHER             </option>
                 <option>ELEKSA             </option>
                 <option>FAW                </option>
                 <option>FERGUSON           </option>
                 <option>FIAT               </option>
                 <option>FORD               </option>
                 <option>FOTON              </option>
                 <option>FUDI               </option>
                 <option>FUSO               </option>
                 <option>GAZ                </option>
                 <option>GEELY              </option>
                 <option>GONOW              </option>
                 <option>GREAT WALL MOTORS  </option>
                 <option>HAFEI              </option>
                 <option>HAVAL              </option>
                 <option>HILLMAN            </option>
                 <option>HINO               </option>
                 <option>HOLDEN             </option>
                 <option>HONDA              </option>
                 <option>HUMMER             </option>
                 <option>HYUNDAI            </option>
                 <option>INEOS              </option>
                 <option>INFINITI           </option>

                 <option>INTERNATIONAL      </option>

                 <option>ISUZU              </option>
                 <option>IVECO              </option>
                 <option>JAC MOTORS         </option>
                 <option>JAGUAR             </option>
                 <option>JEEP               </option>
                 <option>JINBEI             </option>
                 <option>JMC                </option>
                 <option>KIA                </option>
                 <option>KING LONG          </option>
                 <option>LADA               </option>
                 <option>LANCIA             </option>
                 <option>LAND ROVER         </option>
                 <option>LANDWIND           </option>
                 <option>LEXUS              </option>
                 <option>LEYLAND            </option>
                 <option>LIFAN              </option>
                 <option>LINDE              </option>
                 <option>LISTER             </option>
                 <option>MAHINDRA           </option>
                 <option>MAN                </option>
                 <option>MAXUS              </option>
                 <option>MAZDA              </option>
                 <option>MEIYA              </option>
                 <option>MERCEDES           </option>
                 <option>MG                 </option>
                 <option>MINI               </option>
                 <option>MITSUBISHI         </option>
                 <option>NISSAN             </option>
                 <option>OPEL               </option>
                 <option>PERKINS            </option>
                 <option>PETTER             </option>
                 <option>PEUGEOT            </option>

                 <option>PORSCHE            </option>
                 <option>PROTON             </option>
                 <option>RAMBLER            </option>
                 <option>RENAULT            </option>
                 <option>ROVER              </option>

                 <option>SAAB               </option>
                 <option>SEAT               </option>

                 <option>SMART              </option>
                 <option>SOYAT              </option>
                 <option>SSANG-YONG         </option>
                 <option>SUBARU             </option>
                 <option>SUZUKI             </option>
                 <option>TATA               </option>
                 <option>TOYOTA             </option>
                 <option>TRIUMPH            </option>
                 <option>UD TRUCKS          </option>
                 <option>VAUXHALL           </option>
                 <option>VOLKSWAGEN         </option>
                 <option>VOLVO              </option>
                 <option>WILLYS             </option>
                 <option>ZOTYE              </option>
                </select>

                </div>
                <div className="w-1/3 ">
                    <label className="block">
                        Fuel Type
                    </label>
                    <select name="fuelType"  value={data.fuelType}  onChange={fuelChange}  className="pl-2 py-1  outline-none w-3/4">
                    <option value="a">Select</option>
                        <option value="P">
                            P Petrol
                        </option>
                        <option value="D">D Diesel</option>
                        <option value="H">H Hybrid</option>
                        <option value="E">E Electric</option>
                    </select>

                </div>
                <div className="w-1/3">
                    <label className="block">
                        Litres (Cc)
                    </label>
                    <select name="cc" onChange={inputChangeHandler}  value={data.cc} className="pl-2 py-1  outline-none w-3/4">
                    <option value="a">Select</option>
                   <option>1,0</option>
                   <option>1,2</option>
                   <option>1,3</option>
                   <option>1,4</option>
                   <option>1,5</option>
                   <option>1,6</option>
                   <option>1,8</option>
                   <option>1,9</option>
                   <option>2,0</option>
                   <option>2,2</option>
                   <option>2,4</option>
                   <option>2,5</option>
                   <option>2,6</option>
                   <option>2,7</option>
                   <option>2,8</option>
                   <option>3,0</option>
                   <option>3,3</option>
                   <option>3,4</option>
                   <option>3,5</option>
                   <option>3,6</option>
                   <option>3,7</option>
                   <option>3,9</option>
                   <option>4,0</option>
                   <option>4,1</option>
                   <option>4,2</option>
                   <option>4,5</option>
                   <option>4,7</option>
                   <option>5,3</option>
                   <option>6,5</option>
                    </select>

                </div>


            </div>
           <div className="flex mt-4">
            <div className="w-1/3">
                    <label className="block">
                        Model
                    </label>
                    <input type="text" name="model" value={data.model} onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4"/>
            </div>
            <div className="w-1/3">
                    <label className="block">
                        CYL
                    </label>
                    <input type="text" name="cyl" value={data.cyl}   onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4"/>
            </div>
            <div className="w-1/3">
                    <label className="block">
                        Body
                    </label>
                    <input type="text" name="body"  value={data.body}   onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4"/>
            </div>

           </div>
           <div className="flex mt-4">
                <div className="w-1/3 ">
                <label className="block">
                Engine Remark
                    </label>
                    <input type="text" name="engineType" value={data.engineType}   onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4"/>
                </div>
                <div className="w-1/3">
                <label className="block">
                Engine Code
                    </label>
                    <input type="text" name="code" value={data.code}   onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4" />
                </div>
                <div className="w-1/3">
                <label className="block">
               Years
                    </label>
                    {console.log('+++++ years ::::',startYaers)}
                    <Space direction="horizontal">
                    <DatePicker  placeholder="Start" value={startYaers} format={'YYYY'} onChange={onChangeStart} picker="year" /> - <DatePicker name="endYears"  value={endYears}  placeholder="End" onChange={onChangeEnd} picker="year" />
                    </Space>
                </div>
           </div>

           <div className="flex  mt-4">
           <div className="w-1/3 ">
                <label className="block">
               Bore
                    </label>
                    <input type="text" name="bore" value={data.bore}   onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4"/>
                </div>

                <div className="w-1/3 ">
                <label className="block">
               CAM
                    </label>
                    <input type="text" name="cam" value={data.cam}   onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4"/>
                </div>
                <div className="w-1/3">
                <label className="block">
               KW
                    </label>
                    <input type="text" name="kw" value={data.kw}   onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4" />
                </div>


           </div>

           <div className="w-2/4 pr-12 mt-4"> <ImageUpload baseURL={baseURL} updateImages={updateImages}/></div>
            <div className="flex justify-center space-x-8">
            <button className="btn mt-4 btn-danger " onClick={props.onClose}>Cancel</button>
            <button className="btn mt-4 btn-hero " onClick={submitHandler}>Update</button>
            </div>

        </div>
    )

}


export default EditEngine
