import Wrapper from '../../assets/Wrappers/Navbar'
import { FaAlignLeft, FaUserCircle, FaCaretDown } from 'react-icons/fa'
// import { useAppContext } from '../context/appContext'
import Logo from './Logo'
import { useState } from 'react'
import { useDispatch , useSelector } from 'react-redux'
import { uiActions } from '../../store/ui-slice'
import { useNavigate } from 'react-router-dom'



const Navbar = (props) => {
  const [showLogout, setShowLogout] = useState(false)

  // let showSideBar=useSelector((state)=>state.ui.showSideBar)
//   const { toggleSidebar, logoutUser, user } = useAppContext()



let dispatch=useDispatch()
let NavigationHeading=useSelector(state=>state.ui.NavigationHeading);
let user=useSelector(state=>state.ui.user);
let navigate=useNavigate();
// user=JSON.parse(user)
let toggleSidebar=()=>{
  dispatch(uiActions.toggleSideBar())
}

console.log('++++++++++ show side bar ....',user)
  return (
    <Wrapper>
      <div className='nav-center'>
        <button type='button' className='toggle-btn' onClick={toggleSidebar}>
          <FaAlignLeft />
        </button>
        <div>
          {/* <Logo /> */}
          <h3 className='logo-text'>{NavigationHeading}</h3>
        </div>
        <div className='btn-container'>
          <button
            type='button'
            className='btn'
            onClick={() => setShowLogout(!showLogout)}
          >
            <FaUserCircle />
            {user.name}
            <FaCaretDown />
          </button>
          <div className={showLogout ? 'dropdown show-dropdown' : 'dropdown'}>
            <button type='button' className='dropdown-btn' onClick={()=>{dispatch(uiActions.logout()); navigate('/login')}}>
              logout
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Navbar
