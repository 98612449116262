import links from './../../utils/links'
import { NavLink } from 'react-router-dom'
// import Products from '../../pages/Products'
import { useState } from 'react'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { FaUserCog } from "react-icons/fa";
import { useSelector } from 'react-redux';  


const NavLinks = ({ toggleSidebar }) => {

  let user=useSelector(state=>state.ui.user)
  let [isShown,setIsShown]=useState(false)
  return (
    <div className='nav-links'>
      {links.map((link) => {
        const { text, path, id, icon } = link
        return(
          <div key={link.id}>

<NavLink
            to={path}
            key={id}
            onClick={toggleSidebar}
            className={({ isActive }) =>
              isActive ? 'nav-link active' : 'nav-link'
            }
          >
            <span className='icon'>{icon}</span>
            {text}
          </NavLink>

          
          
          </div>
        )

          
      })}

            {user.isSuperAdmin&&<>
              <NavLink
            to='/admin'
            key="11"
            onClick={toggleSidebar}
            className={({ isActive }) =>
              isActive ? 'nav-link active' : 'nav-link'
            }
          >
            <span className='icon'><FaUserCog /></span>
           Admins
          </NavLink>
          <NavLink
            to='/admin/add'
            key="12"
            onClick={toggleSidebar}
            className={({ isActive }) =>
              isActive ? 'nav-link active' : 'nav-link'
            }
          >
            <span className='icon'><FaUserCog /></span>
          Add  Admin
          </NavLink>
            </>}
           
    </div>
  )
}

export default NavLinks
