import { createSlice } from "@reduxjs/toolkit";

let uiSlice=createSlice({
    name:"ui",
    initialState:{
        showSideBar:false,
        NavigationHeading:'Dashboard',
        baseURL:'https://adminserver.hashtechcarparts.com/admin/',
        engines:[],
        token:'',
        user:{},
        isLoggedIn:false,
    },
    reducers:{
        toggleSideBar(state){
            state.showSideBar=!state.showSideBar
        },
        updateHeading(state,action){
            state.NavigationHeading=action.payload.heading
        },
        updateEngines(state,action){
            state.engines=action.payload.engines
        },
        loggedIn(state,action){
            let user=JSON.parse(action.payload.user)
            state.token=action.payload.token;
            state.user=user;
            state.isLoggedIn=action.payload.isLoggedIn
            },
            logout(state){
                state.token='';
            state.user={};
            state.isLoggedIn=false
            localStorage.clear();
            }
    }
})

export let uiActions=uiSlice.actions ;


export default uiSlice;
