import { AiFillSetting ,AiFillCar} from 'react-icons/ai'

let CountBar=(props)=>{
return(
    <div className="bg-white py-4 px-8 rounded-b-lg rounded-t-sm border-b-4 border-primaryColor">
        <div className="flex justify-between">
                <h1 className='font-bold text-primaryColor'>{props.count}</h1>
                <div className='text-secondaryColor'>{props.icon}</div>
                
        </div>
        <h4>{props.text}</h4>
    </div>
)

}

export default CountBar