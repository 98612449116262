import { useEffect, useState , useCallback } from 'react'
import CountBar from '../components/Dashboard/CountBar'


import { AiFillSetting ,AiFillCar} from 'react-icons/ai'
import {FaUserAlt} from 'react-icons/fa';
import SmallModal from './../UI/SmallModal';
import { FaSpinner } from "react-icons/fa";
import { useSelector } from 'react-redux';
import axios from 'axios';

let Dashboard=()=>{

    let [data,setData]=useState({engines:0,parts:0,customers:0})
    let [loading,setLoading]=useState(false)
    let [error,setError]=useState(null)
    let baseURL=useSelector(state=>state.ui.baseURL)
    let getCount = useCallback(async () => {
        // console.log('++++++ api call ????????')
        setLoading(true)
          setError(null);
          try {
            let response = await axios.get(baseURL+"apis/getCount");
           
            if (response.status !== 200) {
              throw new Error("Something Went Wrong Try Again");
            }
            if (response.data.code !== 200) {
              throw new Error(response.data.message);
            }
            // console.log('+++++ cout response------------',response.data)
            setData({...data,'engines':response.data.engineCount,'parts':response.data.partsCount,'customers':response.data.userCount})
            // console.log('+++++++data ---',response.data)
            // setPartsList(response.data.parts);
            // setFilteredList(response.data.parts);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            setError(error.message);
          }
        }, []);

    useEffect(()=>{
        getCount();
    },[getCount])

    return(
        <div className='w-full'>
            {error&&<p>{error}</p>}
            {loading&&<SmallModal><FaSpinner  className='animate-spin text-primaryColor text-8xl'/></SmallModal>}
            <div className='lg:flex lg:space-x-8'>
                
                <div className="lg:w-1/3"> <CountBar count={data.engines} icon={<AiFillCar className='text-5xl'/>} text="Engines"/></div>
           <div className='lg:w-1/3'> <CountBar count={data.parts} icon={<AiFillSetting className='text-5xl'/>} text="Parts"/></div>
           <div className='lg:w-1/3'> <CountBar count={data.customers} icon={<FaUserAlt className='text-5xl'/>} text="Customers"/></div>
           
           
            </div>
            
        </div>
    )
}


export default Dashboard