import Wrapper from './../../assets/Wrappers/SmallSidebar'
import { FaTimes } from 'react-icons/fa'
// import { useAppContext } from '../context/appContext'

// import { useSelector , useDispatch} from 'react-redux/'
import { uiActions } from '../../store/ui-slice'


import Logo from './Logo'
import NavLinks from './NavLinks'
import { useDispatch } from 'react-redux'

const SmallSidebar = (props) => {
  // const { showSidebar, toggleSidebar } = useAppContext()

  let showSideBar=props.showSideBar
  let dispatch=useDispatch()

  let toggleSidebar=()=>{
    dispatch(uiActions.toggleSideBar())
  }

  return (
    <Wrapper>
      <div
        className={
          showSideBar ? 'sidebar-container show-sidebar' : 'sidebar-container'
        }
      >
        <div className='content'>
          <button type='button' className='close-btn' onClick={toggleSidebar}>
            <FaTimes />
          </button>
          <header>
            <Logo />
          </header>
          <NavLinks toggleSidebar={toggleSidebar} />
        </div>
      </div>
    </Wrapper>
  )
}

export default SmallSidebar
