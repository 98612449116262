import { PlusOutlined } from "@ant-design/icons";
import axios from 'axios';
import {useState} from 'react';


let ImageUpload=(props)=>{
    let [error,setError]=useState()
    let [selectImage,setSelectImage]=useState(props.image)
    


    let uploadFileHAndler=async(fileArg)=>{
       
        let fileType=fileArg.type.split('/').pop()
        setError(null)
        try{
    
          let res=await axios.get(props.baseURL+'apis/getpresignedurl?type='+fileType)
          if(res.status !== 200){
            throw new Error("Something Went Wrong Try Again");
          }
          console.log('++++++ reponse after getting url',res.data)
          let url=res.data.url
          let key=res.data.key
    
          let s3Response=await axios.put(url,fileArg,{
            headers:{"Content-Type":fileType}
        })
        console.log('++++ reponse after uploading file',s3Response)
        if(s3Response.status !== 200){
          throw new Error("Something Went Wrong Try Again");
        }
        
        // let imageUrl=k
      
       
        // imagesArray=imagesArray.concat(data.images)
        props.updateImages(key)
        setSelectImage(key)
        // updatedata({
        //   ...data,images:imagesArray
        // })
    
       
    
        }catch(err){
          setError(err.message)
        }
    
      
       
    
        // console.log('++++++++ url',url)
        // let url='https://ecomerece-admin-bucket.s3.us/%20East%20(N.%20Virginia)%20us-east-1.amazonaws.com/product/7ffc3dc0-46e0-11ee-bce3-bda92c5214a6.jpeg?Content-Type=jpeg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYAHSV4RNQFYP7KUT%2F20230830%2FUS%20East%20%28N.%20Virginia%29%20us-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230830T025412Z&X-Amz-Expires=900&X-Amz-Signature=2886d2d774ee44d08e964bbaba8f993c8a4e7def6584520c4784e59b55eb815a&X-Amz-SignedHeaders=host'
        // let response=await fetch(url,{
        //     method:'PUT',
        //     body:{file:file},
        //     headers:{"Content-Type":file.type}
        // })
    
        
    
        
    }
    let fileChangeHandler=(e)=>{
    
        // console.log('======= file change %%$$$##########',e.target.file)
        // updateFile(e.target.files[0])
        if(e.target.files[0]){
          uploadFileHAndler(e.target.files[0])
        }else{
          return null
        }
        
    
       
      }

    return(
        <div className="flex space-x-1 bg-white p-2 my-2">
            {error&&<p>{error}</p>}
            {selectImage&&<div>

<img src={'https://hashtechcarparts.s3.amazonaws.com/'+selectImage+''} className="w-28 h-28 rounded-lg" alt={selectImage}/>
</div>}
           
            {/* upload file html */}
          <div>
        <label htmlFor="inputFile">
          <div className="text-center w-28 border border-dotted border-gray-400 hover:border-blue-700 rounded-full p-6">
            <PlusOutlined className="text-xl mb-1" />
            <h6>Image</h6>
          </div>
        </label>
        {/* props.fileChangeHandle */}
        {/* (e)=>{console.log('+++++++++++ file change',e.target.files[0])} */}
        <input id="inputFile" onChange={fileChangeHandler} type="file" className="hidden" />
      </div>
                

            </div>
    )
}


export default ImageUpload