import {useEffect , useState,useCallback} from 'react'
import axios from 'axios'
import {useSelector,useDispatch} from 'react-redux'
import { uiActions } from '../store/ui-slice';

import { FaTrashAlt } from "react-icons/fa";
import { FaEdit , FaTrashRestore } from "react-icons/fa";
import Modal from './../UI/Modal'
import DeletModel from '../components/Helper/DeleteModel';
import UnDeleteModel from '../components/Helper/UnDeleteModel';

import { notification  } from 'antd';


let AllUser=()=>{

    let [usersList, setUsersList] = useState([]);
    let [loading, setLoading] = useState(true);
    let [error, setError] = useState(null);
    let [showModel,setShowModel]=useState(false);
    let [selectUser,setSelectUser]=useState(null);
    let [restoreModel,setRestoreModel]=useState(false);
   
    const [api, contextHolder] = notification.useNotification();


   

    let baseURL=useSelector(state=>state.ui.baseURL)
    let dispatch=useDispatch()

   let onClose=()=>{
    console.log('++++++++ fucntion call')
    setShowModel(false)
   }

   let onCloseRestore=()=>{
      setRestoreModel(false)
   }

//  show notofocation

   const openNotificationWithIcon = (type,msg,description) => {
    api[type]({
      message: msg,
      description:description,
    });
  };

    let getAllUsers = useCallback(async () => {
        setError(null);
        try {
          let response = await axios.get(baseURL+"user/getAll");
         
          if (response.status !== 200) {
            throw new Error("Something Went Wrong Try Again");
          }
          if (response.data.code !== 200) {
            throw new Error(response.data.message);
          }
          // console.log('+++++++data ---',response.data)
          setUsersList(response.data.users);
          setLoading(false);
        } catch (error) {
          setError(error.message);
        }
      }, []);


   let   afterDeleteHandler=(obj,msg,type)=>{
   
        let indexOfObject=usersList.findIndex(item=>item._id===obj._id)
        usersList[indexOfObject]=obj;
        
        setUsersList(usersList)
        openNotificationWithIcon(type,'',msg)
        
      }
     
    
      useEffect(() => {
        dispatch(uiActions.updateHeading({heading:'Customers'}))
        getAllUsers();
      }, [getAllUsers]);

return(
    <div>
        
      {showModel&&<Modal><DeletModel id={selectUser} onClose={onClose} afterDeleteHandler={afterDeleteHandler} api={`${baseURL}user/delete`}/></Modal>}
       {restoreModel&&<Modal><UnDeleteModel id={selectUser} onCloseRestore={onCloseRestore} afterDeleteHandler={afterDeleteHandler} api={`${baseURL}user/delete`}/></Modal>}
       
       {contextHolder}
       
        <table className='w-full table-auto mt-3 text-left'>
            <thead className='shadow-lg'>
                <tr>
                <th>Name</th>
            <th>Email</th>
            <th>Mobile Number</th>
            <th>Address</th>
           
            <th>Action</th>
                </tr>
          
            </thead>
            <tbody>
              {usersList&&usersList.length>0&&usersList.map(item=>{
                return(
                  <tr className='border-b'>
                    <td className='py-1'>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.mobileNumber}</td>
                    <td>{item.address}</td>
                    {item.isDeleted?<td className='text-blue-500 text-lg'><FaTrashRestore onClick={()=>{setSelectUser(item._id); setRestoreModel(true)}}/></td>: <td className='text-red-500 text-lg'><FaTrashAlt onClick={()=>{setSelectUser(item._id); setShowModel(true)}}/></td>}
                   
                  </tr>
                )
              })}
                
            </tbody>
        </table>
    </div>
)

}

export default AllUser