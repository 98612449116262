import logo from './../../assets/images/logo.jpg'

let Logo=()=>{
return(
    <>
<img src={logo} width="200px" height="150px" alt='HashTech' className='logo bg-white' /> 

</>
)
}

export default Logo