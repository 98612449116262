// import logo from './logo.svg';
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Root from "./pages/Root";

import './App.css';
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import Dashboard from './pages/Dashboard';

// import Category from './pages/Category'

import { useDispatch } from "react-redux";
import {fetchAllProductsData} from './store/product-actions'
import {useEffect} from 'react'
import AddEngine from "./pages/AddEngine";
import AddParts from "./pages/AddParts";
import AddUser from "./pages/AddUser";
import AllEngine from "./pages/AllEngine";
import AllUser from "./pages/AllUser";
import AllParts from "./pages/AllParts";
import AllAdmins from "./pages/AllAdmins";
import AddAdmins from "./pages/AddAdmin";


let router=createBrowserRouter([
  {
    path:'/',
    element:<Root/>,
    children:[
      {index:true,element:<Dashboard/>},
      {path:'addengine',element:<AddEngine/>},
      {path:'addparts',element:<AddParts/>},
      {path:'engines',element:<AllEngine/>},
      {path:'user',element:<AllUser/>},
      {path:'user/add',element:<AddUser/>},
      {path:'parts',element:<AllParts/>},
      {path:'admin',element:<AllAdmins/>},
      {path:'admin/add',element:<AddAdmins/>}
    
    ]

},{
  path:'/login',
  element:<Login/>
}
,{
  path:'*',
  element:<ErrorPage/>
}
])

function App() {

  let dispatch=useDispatch()

  useEffect(() => { 
   
    // dispatch(fetchCategoryData());
    // dispatch(fetchBrandData());
    // dispatch(fetchAllProductsData())
  
  
}, []);

  return (
   <RouterProvider router={router}/>
  );
}

export default App;
