import { useState, useEffect,useCallback } from "react"
import axios from 'axios'
import { useSelector , useDispatch } from "react-redux"
import ImageUpload from "../components/Parts/ImageUpload"
import { uiActions } from "../store/ui-slice"
import Select from 'react-select'

import { DatePicker,notification, Space  } from 'antd';

let initialState={
    engine:[],
    manufactureCode:'',
    adminCode:'',
    name:'',
    brand:'',
    description:'',
    image:''
}

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ];

let AddParts=()=>{
let [data,setData]=useState(initialState)
let [error,setError]=useState(null)
let baseURL=useSelector(state=>state.ui.baseURL)
let enginesList=useSelector(state=>state.ui.engines);
let [options,setOptions]=useState([])
const [api, contextHolder] = notification.useNotification();

let dispatch=useDispatch()

let inputChangeHandler=(e)=>{
    setData({...data,[e.target.name]:e.target.value})
}

let updateImages=(image)=>{
    setData({...data,['image']:image})

}

//  defien notification

const openNotificationWithIcon = (type,msg,description) => {
  api[type]({
    message: msg,
    description:description,
  });
};

let submitHandler=async ()=>{

    setError(null)
    try{
        let response=await axios.post(baseURL+'parts/add',data)
        if (response.status !== 200) {
            throw new Error("Something Went Wrong Try Again");
          }
          if (response.data.code !== 200) {
            throw new Error(response.data.message);
          }
          let message="Successfully Add Engine"
          openNotificationWithIcon('success','',message)
          setData(initialState)

    }catch(error){
      openNotificationWithIcon('error','Error',error.message)
        // setError(error.message);
    }

}
// console.log('++++ data ----',data)

let modalChangeHandler=(e)=>{
  let tempArray=e.map(item=>{return item.value})
  setData({...data,'engine':tempArray})


}


let fetchEngineList = useCallback(async () => {
    setError(null);
    try {
      let response = await axios.get(baseURL+"engine/models");
      console.log('+++++ response :::: ??????',response.data);
      if (response.status !== 200) {
        throw new Error("Something Went Wrong Try Again");
      }
      if (response.data.code !== 200) {
        throw new Error(response.data.message);
      }
      let tempArray=[]
      tempArray=response.data.models.map(item=>{
        return   { value: item._id, label: `${item.make}-${item.model}-${item.fuelType}-${item.code}-${item.cc}-${item.years}-${item.body}` }
      })
      // setOptions(tempArray)
      dispatch(uiActions.updateEngines({engines:tempArray}))
      // console.log('+++++++data ---',response.data)
      // setEnginesList(response.data.engines);

      // setLoading(false);
    } catch (error) {
      setError(error.message);
    }
  }, []);

  // console.log('+++++ engines list',enginesList)

useEffect(()=>{
      
    dispatch(uiActions.updateHeading({heading:'Add New Spare Parts'}))
    if(enginesList&&enginesList.length===0){
      fetchEngineList();
    }
    // console.log('=+++ api call');
},[fetchEngineList])

    return(
        <div>


{contextHolder}
                {error&&<p className="alert alert-danger">{error}</p>}
            <div className="flex mt-4">
                <div className="w-2/3 ">
                <label className="block">
                Engine
                    </label>
                    {enginesList&&enginesList.length>0?<Select
                    closeMenuOnSelect={false}
            onChange={modalChangeHandler}
              isMulti
              name="colors"
              options={enginesList}
              className="w-10/12 outline-none"
              classNamePrefix="select"
            />:'Loading'}
                    
                </div>
                <div className="w-1/3">
                <label className="block">
                Manufacture Code
                    </label>
                    <input type="text" name="manufactureCode" value={data.manufactureCode} onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4" />
                </div>
               
           </div>
           <div className="flex mt-4">
           <div className="w-1/3">
                <label className="block">
               Admin Code
                    </label>
                    <input type="text" name="adminCode" value={data.adminCode}  onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4"/>
                </div>
                <div className="w-1/3 ">
                <label className="block">
                Name
                    </label>
                    <input type="text" name="name" value={data.name}  onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4"/>
                </div>
                <div className="w-1/3">
                <label className="block">
               brand
                    </label>
                    <input type="text" name="brand" value={data.brand}  onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4" />
                </div>

           </div>
           <ImageUpload baseURL={baseURL} updateImages={updateImages}/>
           <div>
            <label className="block">
                Description
            </label>
           <textarea
           name="description"
           value={data.description}
           onChange={inputChangeHandler}
           className="w-1/2 outline-none p-1"
            rows="5"
           />
           </div>
           <button className="btn btn-hero mt-4" onClick={submitHandler}>Save</button>
        </div>
    )
}


export default AddParts
