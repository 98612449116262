import { useState, useEffect } from "react"
import axios from 'axios'
import { useSelector , useDispatch } from "react-redux"
import ImageUpload from "../components/Parts/ImageUpload"
import { uiActions } from "../store/ui-slice"
import { notification  } from 'antd';

let initialState={
    email:'',
    password:'',
    name:'',
    mobileNumber:'',
    address:'',
    
}

let AddUser=()=>{
let [data,setData]=useState(initialState)
let [error,setError]=useState(null)
let baseURL=useSelector(state=>state.ui.baseURL)
const [api, contextHolder] = notification.useNotification();

let dispatch=useDispatch()

let inputChangeHandler=(e)=>{
    setData({...data,[e.target.name]:e.target.value})
}

const openNotificationWithIcon = (type,msg,description) => {
    api[type]({
      message: msg,
      description:description,
    });
  };

let submitHandler=async ()=>{

    setError(null)
    try{
        let response=await axios.post(baseURL+'user/add',data)
        if (response.status !== 200) {
            throw new Error("Something Went Wrong Try Again");
          }
          if (response.data.code !== 200) {
            throw new Error(response.data.message);
          }
          else{
            let message="User Successfully Registered"
            openNotificationWithIcon('success','',message)
        setData(initialState)
          }
         
         
    }catch(error){
        openNotificationWithIcon('error','Error',error.message)
    }

}


useEffect(()=>{

    dispatch(uiActions.updateHeading({heading:'Add New User'}))
    
},[])

    return(
        <div>
                  {contextHolder}
               
            <div className="flex mt-4">
                <div className="w-1/3 ">
                <label className="block">
                Name 
                    </label>
                    <input type="text" name="name" value={data.name} onChange={inputChangeHandler} className="pl-2 py-1  outline-none w-3/4"/>
                </div>
                <div className="w-1/3">
                <label className="block">
                Email
                    </label>
                    <input type="email" name="email" value={data.email} onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4" />
                </div>
                <div className="w-1/3">
                <label className="block">
              Mobile Number
                    </label>
                    <input type="text" name="mobileNumber" value={data.mobileNumber}  onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4"/>
                </div>
           </div>
           <div className="flex mt-4">
                <div className="w-1/3 ">
                <label className="block">
                Password
                    </label>
                    <input type="password" name="password" value={data.password}  onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4"/>
                </div>
                <div className="w-2/3">
                <label className="block">
               Address
                    </label>
                    <input type="text" name="address" value={data.address}  onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4" />
                </div>
               
           </div>
           
           
           <button className="btn btn-hero mt-4" onClick={submitHandler}>Register</button>
        </div>
    )
}


export default AddUser