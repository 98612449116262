import logo from '../assets/images/logo.jpg';
import {useState} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

let initialState={
    email:'',
    password:''
}

let Login=()=>{

let [data,setData]=useState(initialState);
let [error,setError]=useState(null);
let [loading,setLoading]=useState(false);

const navigate = useNavigate();


let baseURL=useSelector(state=>state.ui.baseURL)

let onChangeHandler=(e)=>{
setData({...data,[e.target.name]:e.target.value})
}

let submitHandler=async (e)=>{
    setError(null)
    setLoading(true)
e.preventDefault();
try{

       let  response=await axios.post(baseURL+'admin/login',data)
    //    console.log('+++++ response',response)
       if(response.status===422){
        throw new Error('Format of Input fields is incorect')
       }
        else if(response.status!==200){
                throw new Error('Something Went Wrong')
        }else{
           if(response.data.code===200){
                let time=new Date().getTime()
            //    console.log('++++ api hit',response.data.user)
                localStorage.setItem('token',response.data.token)
                localStorage.setItem('expireIn',time+(2*60*60*1000))
                localStorage.setItem('user',JSON.stringify(response.data.user))
                setLoading(false)
               return  navigate('/');
                // return redirect('/cart');
           }else{
            
            throw new Error(response.data.message)
           } 
        }


}catch(error){
    setLoading(false)
    setError(error.message)
}

}



return(
    <div  className='p-4'>
    
        <div className='w-full mt-16 my-auto bg-white shadow-lg rounded-xl p-4 mx-auto md:w-1/2 lg:w-1/3 '>
        <img src={logo} alt="logo" className='w-4/6 h-24 mx-auto'/>
        {error&&<p className='border border-red-600 px-4  text-red-500 font-bold py-1 text-center'>{error}</p>}
        <hr className='my-2'/>
        <form className='px-2 rounded-md bg-gray-50 py-2' onSubmit={submitHandler}>
            <label>Email</label>
        <input  type="email" value={data.email} name="email" onChange={onChangeHandler} required className='outline-none border w-full py-1 px-2 rounded-md' placeholder="email"/>
            <label>Password</label>
        <input  type="password" value={data.password} name="password" onChange={onChangeHandler}  required className='outline-none border w-full py-1 px-2 rounded-md' placeholder="password"/>
       
        <button type='submit' disabled={loading} className='mx-auto bg-primaryColor py-1 text-xl px-6 rounded-lg text-white mt-2'>Login</button>
        </form>
        <p className='mt-2 px-2'>If you don't have account please contact us at <span className='text-primaryColor text-lg'>abcd@xyz.com</span></p>
        </div>
        
        



    </div>
)

}

export default Login