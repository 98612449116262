import { IoBarChartSharp } from 'react-icons/io5'

import {FaUserAlt,FaUserPlus} from 'react-icons/fa'

import { AiFillSetting ,AiFillCar} from 'react-icons/ai'




const links = [
  { id: 1, text: 'Dashboard', path: '/', icon: <IoBarChartSharp /> },
  { id: 2, text: 'Parts', path: 'parts', icon: <AiFillSetting /> },
  { id: 3, text: 'Add Parts', path: 'addparts', icon: <AiFillSetting /> },
  { id: 4, text: 'Engine', path: 'engines', icon: <AiFillCar /> },
  { id: 5, text: 'Add Engine', path: 'addengine', icon: <AiFillCar /> },
  { id: 6, text: 'User', path: 'user', icon: <FaUserAlt /> },
  { id: 7, text: 'Add User', path: 'user/add', icon: <FaUserPlus /> },

]

export default links
