import {useState} from 'react'
import axios from 'axios';

let DeletModel = (props) => {
    let [updateObject,setUpdateObject]=useState(null)
    let [status,updateStatus]=useState(1)
    let [error,setError]=useState(null)

    let DeleteHandler=async ()=>{
        updateStatus(2)
        let id=props.id
        // console.log('+++++++ id',id)
        try{
            let response=await axios.put(props.api,{id:props.id,isDeleted:true})
            // let dat=result.response()
            // if(dat.status!==200)
            // console.log('+++++++ response',result)
            if(response.status!=200){
                // console.log('_______ woking inside if !==200')
                updateStatus(1)
                throw new Error('Something Went Wrong, Try Again')
            }
            if(response.data.code===200){
              // console.log('+++++++ response',response.data)
                updateStatus(1)
               props.afterDeleteHandler(response.data.result,'Successfully Deleted','error')
                props.onClose()
            }
            else{
                throw new Error(response.data.message)
            }

        }catch(error){
            // updateNameValid(true)
            updateStatus(1)
            setError(error.message)
        }

    }

  return (
    <div>
      
      {error&&<p className='alert-danger'>{error}</p>}
      <h1 className="text-red-500 text-center">Delete</h1>
      <p className="text-center text-lg my-8">
        Are you sure you want to delete ?
      </p>
      <div className="space-x-8 mt-5 text-center">
        <button className="btn btn-hapster" onClick={props.onClose}>
          Cancel
        </button>
        <button disabled={status===2} className="btn btn-danger" onClick={DeleteHandler}>Delete</button>
      </div>
    </div>
  );
};

export default DeletModel;
