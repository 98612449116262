import { useState, useEffect,useCallback  } from "react"
import axios from 'axios'
import { useSelector , useDispatch } from "react-redux"
// import ImageUpload from "../components/Parts/ImageUpload"
// import { uiActions } from "../store/ui-slice"

import ImageUpload from './ImageUpload';
import { uiActions } from "../../store/ui-slice";
import Select from 'react-select'



let EditPart=(props)=>{

    // console.log('++++ props. engine',props.item.engine)

    let initialState={
        engine:props.item.engine,
        manufactureCode:props.item.manufactureCode,
        adminCode:props.item.adminCode,
        name:props.item.name,
        brand:props.item.brand,
        description:props.item.description,
        image:props.item.image
    }


let [data,setData]=useState(initialState)
let [error,setError]=useState(null)
let baseURL=useSelector(state=>state.ui.baseURL)

let enginesList=useSelector(state=>state.ui.engines)

let dispatch=useDispatch()

let inputChangeHandler=(e)=>{
    setData({...data,[e.target.name]:e.target.value})
}

let updateImages=(image)=>{
    setData({...data,['image']:image})
    
}

let modalChangeHandler=(e)=>{
    let tempArray=e.map(item=>{return item.value})
    setData({...data,'engine':tempArray})
  
  
  }
  

let submitHandler=async ()=>{
    // console.log('+++++++data  before submission',data)
    setError(null)
    try{
        let response=await axios.put(baseURL+'parts/update/'+props.item._id,data)
        if (response.status !== 200) {
            throw new Error("Something Went Wrong Try Again");
          }
          if (response.data.code !== 200) {
            throw new Error(response.data.message);
          }else{
            // console.log('++++++++ response',response.data.result)
            props.updateOBjectsArray(response.data.result,response.data.message,'success');
            
          }
        
         
    }catch(error){
        setError(error.message);
    }

}

   //  engines list
   let fetchEngineList = useCallback(async () => {
    setError(null);
    try {
      let response = await axios.get(baseURL+"engine/models");
      // console.log('+++++ response :::: ??????',response.data);
      if (response.status !== 200) {
        throw new Error("Something Went Wrong Try Again");
      }
      if (response.data.code !== 200) {
        throw new Error(response.data.message);
      }
      let tempArray=[]
      tempArray=response.data.models.map(item=>{
        return   { value: item._id, label: `${item.make}-${item.model}-${item.fuelType}-${item.code}-${item.cc}-${item.years}-${item.body}` }
      })
      dispatch(uiActions.updateEngines({engines:tempArray}))
    //   setOptions(tempArray)
    } catch (error) {
      setError(error.message);
    }
  }, []);


useEffect(()=>{

    dispatch(uiActions.updateHeading({heading:'Add New Spare Parts'}))
    if(enginesList.length===0){
        fetchEngineList();
      }
    // console.log('=+++ api call');
},[])

    return(
        <div className="bg-gray-300 p-4">
              {/* {console.log('++++++ default engine values',props.defaultEngineList)} */}
                {error&&<p className="alert alert-danger">{error}</p>}
                <div className="w-full ">
                <label className="block">
                Engine 
                    </label>
                    {enginesList.length>0?<Select
                    closeMenuOnSelect={false}
            onChange={modalChangeHandler}
            defaultValue={props.defaultEngineList}
              isMulti
              name="colors"
              options={enginesList}
              className="w-10/12 outline-none"
              classNamePrefix="select"
            />:'Loading'}
                </div>
            <div className="flex mt-4">
                
                <div className="w-1/2">
                <label className="block">
                Manufacture Code
                    </label>
                    <input type="text" name="manufactureCode" value={data.manufactureCode} onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4" />
                </div>
                <div className="w-1/2">
                <label className="block">
               Admin Code
                    </label>
                    <input type="text" name="adminCode" value={data.adminCode}  onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4"/>
                </div>
           </div>
           <div className="flex mt-4">
                <div className="w-1/2 ">
                <label className="block">
                Name
                    </label>
                    <input type="text" name="name" value={data.name}  onChange={inputChangeHandler}   className="pl-2 py-1  outline-none w-3/4"/>
                </div>
                <div className="w-1/2">
                <label className="block">
               brand
                    </label>
                    <input type="text" name="brand" value={data.brand}  onChange={inputChangeHandler}  className="pl-2 py-1  outline-none w-3/4" />
                </div>
               
           </div>
           <ImageUpload baseURL={baseURL} image={props.item.image} updateImages={updateImages}/>
           <div>
            <label className="block">
                Description
            </label>
           <textarea
           name="description"
           value={data.description}
           onChange={inputChangeHandler} 
           className="w-1/2 outline-none p-1"
            rows="5"
           />
           </div>
           <div className="flex justify-between">
            <button className="btn btn-danger" onClick={props.onClose}>Cancel</button>
           <button className="btn btn-hero mt-4" onClick={submitHandler}>Update</button>
           </div>
           
        </div>
    )
}


export default EditPart