

import NavLinks from './NavLinks'
import Logo from './Logo'
import Wrapper from './../../assets/Wrappers/BigSideBar'

const BigSidebar = (props) => {
  let showSideBar=props.showSideBar
  console.log(' --- show side bar change in big side bar',showSideBar)
  return (
    <Wrapper>
      <div
        className={
          showSideBar ? 'sidebar-container ' : 'sidebar-container show-sidebar'
        }
      >
        <div className='content'>
          <header>
          <Logo/>
          </header>
          <NavLinks />
        </div>
      </div>
    </Wrapper>
  )
}

export default BigSidebar
