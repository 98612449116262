import {useEffect , useState,useCallback} from 'react'
import axios from 'axios'
import {useSelector,useDispatch} from 'react-redux'
import { uiActions } from '../store/ui-slice';

import { FaTrashAlt ,FaTrashRestore } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Modal from './../UI/Modal'
import DeletModel from '../components/Helper/DeleteModel';
import UnDeleteModel from '../components/Helper/UnDeleteModel';
import EditPart from '../components/Parts/EditPart';

import SmallModal from './../UI/SmallModal';
import { FaSpinner } from "react-icons/fa";


import { notification  } from 'antd';

import { CSVLink ,CSVDownload } from "react-csv";

let headers = [
  { label: "Name", key: "name" },
  { label: "Brand", key: "brand" },
  { label: "Manufacture Code", key: "manufactureCode"},
  { label: "Admin Code", key: "adminCode"},
  { label: "Description", key: "description"},
  { label: "Engine", key: "engine"},
];

let AllParts=()=>{

    let [partsList, setPartsList] = useState([]);
    let [filteredList,setFilteredList]=useState([]);
    let [inputValue,setInputValue]=useState('');
    let [loading, setLoading] = useState(true);
    let [error, setError] = useState(null);
   
    let [showModel,setShowModel]=useState(false)

    let [selectItem,setSelectItem]=useState(null);
    let [editModal,setEditModal]=useState(false);

    let [restoreModel,setRestoreModel]=useState(false);
    let [defaultEngineList,setDefaultEngineList]=useState([])

    let baseURL=useSelector(state=>state.ui.baseURL)
    let dispatch=useDispatch()

   let onClose=()=>{
    setShowModel(false)
   }



    let getAllEngine = useCallback(async () => {
      setLoading(true)
        setError(null);
        try {
          let response = await axios.get(baseURL+"parts/getAll");
         
          if (response.status !== 200) {
            throw new Error("Something Went Wrong Try Again");
          }
          if (response.data.code !== 200) {
            throw new Error(response.data.message);
          }
          // console.log('+++++++data ---',response.data)
          setPartsList(response.data.parts);
          setFilteredList(response.data.parts);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setError(error.message);
        }
      }, []);

     
    
      useEffect(() => {
        dispatch(uiActions.updateHeading({heading:'Spare Parts'}))
        getAllEngine();
      }, [getAllEngine]);

      //  search Filter

        let inputChnageHandler=(e)=>{
          let input=e.target.value
          // const pets = ['cat', 'dog', 'bat'];

// console.log(pets.includes('cat'));
          let tempArray=partsList.filter(item=>item.name.includes(input)||item.brand.includes(input)||item.manufactureCode.includes(input)||item.adminCode.includes(input)||item.engine.includes(input))
          setFilteredList(tempArray);
          setInputValue(input)
        }

       //   update part array

       //  show notofocation

       const [api, contextHolder] = notification.useNotification();

   const openNotificationWithIcon = (type,msg,description) => {
    api[type]({
      message: msg,
      description:description,
    });
  };

  //  default engine list handler

  let defaultEngineListHandler=(engines)=>{
  let tempArray=[]
  tempArray=engines.length>0&&engines.map(item=>{
    return   { value: item._id, label: `${item.make}-${item.model}-${item.fuelType}-${item.code}-${item.cc}-(${item.years})-${item.body}` }
  })

  setDefaultEngineList(tempArray)
  }

         //  Edit Modal

  let onCloseEditModal=()=>{
    setEditModal(false)
  }
       
       let   updateOBjectsArray=(obj,msg,type)=>{
   
        let indexOfObject=partsList.findIndex(item=>item._id===obj._id)
        partsList[indexOfObject]=obj;
        
        setPartsList(partsList);
        onCloseEditModal();
        openNotificationWithIcon(type,'',msg)
        
      }

      let onCloseRestore=()=>{
        setRestoreModel(false)
     }

//   db.parts.findAndUpdate({},{$set:{engine:[ObjectId('65b889dd193c2d9be046dd21')]}},{new:true})

return(
    <div>
      <div className='flex space-x-4 my-2'>
        <input type="text" placeholder=' i.e xyz' value={inputValue} onChange={inputChnageHandler} className='outline-none border border-black p-1 rounded-md'/>
        <button className='btn'>Search</button>
        <CSVLink data={filteredList} className='btn' filename={"parts.csv"} headers={headers}>
  Export File
</CSVLink>
      </div>
      {error&&<p>{error}</p>}
         {contextHolder}
         {loading&&<SmallModal><FaSpinner  className='animate-spin text-primaryColor text-8xl'/></SmallModal>}
         {showModel&&<Modal><DeletModel id={selectItem._id} onClose={onClose} afterDeleteHandler={updateOBjectsArray} api={`${baseURL}parts/delete`}/></Modal>}
       {restoreModel&&<Modal><UnDeleteModel id={selectItem._id} onCloseRestore={onCloseRestore} afterDeleteHandler={updateOBjectsArray} api={`${baseURL}parts/delete`}/></Modal>}
        {editModal&&<Modal><EditPart defaultEngineList={defaultEngineList} updateOBjectsArray={updateOBjectsArray} onClose={onCloseEditModal} item={selectItem}/></Modal>}
        <div className='mt-2'>

        <table className='w-full text-left'>
            <thead className='shadow-lg'>
                <tr>
                  <th>Img</th>
                <th>Name</th>
            <th>Brand</th>
            <th>Engine</th>
            <th>Mfr. Code</th>
            <th>Adm Code</th>
           
            <th colSpan={2} className="text-center">Action</th>
                </tr>
          
            </thead>
            <tbody>
              {filteredList&&filteredList.length>0&&filteredList.map(item=>{
                return(
                  <tr className='border-b border-gray-400 py-2' key={item._id}>
                    <td><img src={'https://hashtechcarparts.s3.amazonaws.com/'+item.image+''} className="w-20 h-20 rounded-lg" alt={item.image}/></td>
                    <td>{item.name}</td>
                    <td>{item.brand}</td>
                    <td>
                      <table className='table-fixed border border-gray-300'>
                      {item.engine&&item.engine.length>0&&item.engine.map(itm=>{
                        // console.log('+++++ item.engine',item.engine)
                      return(
                        <tr className='border-b border-gray-300'>
                          <td className='border-r border-gray-300 px-1'>{itm.make}</td>
                          <td className='border-r border-gray-300 px-1'>{itm.fuelType}</td>
                          <td className='border-r border-gray-300 px-1'>{itm.cc}</td>
                          <td className='border-r border-gray-300 px-1'>{itm.model}</td>

                        </tr>
                      )
                    })}
                        </table></td>
                    <td>{item.manufactureCode}</td>
                    <td>{item.adminCode}</td>
                    <td className='text-blue-500 text-lg'><FaEdit onClick={()=>{setSelectItem(item);defaultEngineListHandler(item.engine);setEditModal(true);}}/></td>
                    {item.isDeleted?<td className='text-blue-500 text-lg'><FaTrashRestore onClick={()=>{setSelectItem(item); setRestoreModel(true)}}/></td>: <td className='text-red-500 text-lg'><FaTrashAlt onClick={()=>{setSelectItem(item); setShowModel(true)}}/></td>}
                  </tr>
                )
              })}
                
            </tbody>
        </table>
        </div>
        
    </div>
)

}

export default AllParts