import { createSlice } from "@reduxjs/toolkit";

let productSlice=createSlice({
    name:"product",
    initialState:{
        products:[]
    },
    reducers:{
        fetchAllProduct(state,action){
            console.log('++++++++ in slice',action.payload.products)
            state.products=action.payload.products
        }
    }
})

export let productActions=productSlice.actions ;


export default productSlice;